body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
  font-family: "Segoe UI Emoji", "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Symbol", "Android Emoji", sans-serif;
}

h1 {
  margin-bottom: 20px;
}

.App {
  text-align: center;
  font-family: Arial, sans-serif;
  position: relative;
}

.emoji-grid {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.emoji-cell {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  margin: 0 5px;
}

.emoji-cell.selected {
  border: 2px solid #007bff;
}

.instructions {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

.emoji-palette {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  gap: 5px;
  margin-bottom: 20px;
}

.emoji-option {
  font-size: 24px;
  cursor: pointer;
}

button {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

p {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.guess-history {
  position: absolute;
  top: -400px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  overflow-y: hidden;
  height: 400px; /* Increased to show more history */
  padding-bottom: 10px;
}

.history-grid {
  /* animation: slideUp 0.5s ease-out; */
  margin-bottom: 5px;
  /* transform: scale(0.5); */
  flex-shrink: 0;
}
@keyframes slideUp {
  from {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(0.5);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(0.5);
    opacity: 1;
  }
}

.game-area {
  position: relative;
  z-index: 1;
  background-color: white;
  padding: 25px 5px 5px 5px;
  border-radius: 5px;
}

.emoji-grid {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.emoji-cell {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin: 0 5px;
  cursor: pointer;
}

.emoji-cell.selected {
  border: 2px solid #000;
}


/* Add a gradient background */
body {
  background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
}

/* Add a subtle shadow to the game area */
.game-area {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.game-area:hover {
  transform: translateY(-5px);
}

/* Style the emoji cells with a hover effect */
.emoji-cell {
  transition: all 0.3s ease;
  background-color: #ffffff;
  border-radius: 10px;
}

.emoji-cell:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Add a pulsing animation to the selected emoji cell */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.emoji-cell.selected {
  animation: pulse 1s infinite;
  border-color: #007bff;
}

/* Style the emoji options with a hover effect */
.emoji-option {
  transition: all 0.3s ease;
}

.emoji-option:hover {
  transform: scale(1.2);
  cursor: pointer;
}

/* Add a gradient to the submit button */
button {
  background: linear-gradient(45deg, #4CAF50, #45a049);
  transition: all 0.3s ease;
}

button:hover {
  background: linear-gradient(45deg, #45a049, #4CAF50);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Add a fade-in animation for the instructions */
.instructions {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Improve the guess history animation */
.history-grid {
}

@keyframes slideUp {
  from {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(0.5);
    opacity: 1;
  }
}